import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import axios from "axios"
import { useI18next } from "gatsby-plugin-react-i18next"
import {
  Breadcrumb,
  Typography,
  Button,
  Form,
  Input,
  Divider,
  Modal,
  message,
  Upload,
} from "antd"
import { UploadOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons"

import Layout from "../../../app/layout"
import Seo from "../../../components/seo"
import Container from "../../../components/Container"
import { ChevronRight } from "../../../components/Icon"

import pdfChecker from "../../../utils/pdfChecker"
import fileSizeChecker from "../../../utils/fileSizeChecker"

const TahapEmpatPage = () => {
  const { t } = useI18next()

  const [loading, setLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [modalTitle, setModalTitle] = useState("")

  const [modalDescription, setModalDescription] = useState("")

  const [isDirectLink, setIsDirectLink] = useState(false)

  const [fileList, setFileList] = useState([])

  const [form] = Form.useForm()

  const formConfig = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 12,
    },
    size: "large",
    layout: "vertical",
    scrollToFirstError: true,
    autoComplete: "off",
    className: "flat-form",
  }

  const onFinish = async ({ no_registrasi }) => {
    const formData = new FormData()

    formData.append("dokumen_proposal", fileList[0].originFileObj)
    formData.append("no_registrasi", no_registrasi)

    setLoading(true)

    axios({
      url: `${process.env.GATSBY_API_URL}/frontend/scholarship/business-competition/storeTahap4`,
      method: "post",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        const responseCode = res.data.code
        const responseMessage = res.data.message

        if (responseCode === 200) {
          setModalTitle("Upload Proposal Bisnis Berhasil!")
          setModalDescription(
            "Unggahan proposal bisnis anda telah kami terima. Silakan cek email untuk petunjuk selanjutnya."
          )

          setIsDirectLink(true)
        }

        if (responseCode === 400) {
          setModalTitle("Terjadi Masalah Saat Proses")
          setModalDescription(
            "Ada input data yang perlu diisi, silahkan lengkapi terlebih dahulu."
          )

          if (responseMessage === "No access") {
            setModalDescription("Anda belum/tidak lolos pada tahap sebelumnya.")
          }

          setIsDirectLink(false)
        }

        if (responseCode === 404) {
          setModalTitle("Terjadi Masalah Saat Proses")
          setModalDescription("Nomor registrasi salah.")

          setIsDirectLink(false)
        }

        if (responseCode === 500) {
          setModalTitle("Terjadi Masalah Saat Proses")
          setModalDescription(
            "Silahkan hubungi call center kami di 134 untuk bantuan kendala anda."
          )

          setIsDirectLink(false)
        }
        form.resetFields()
        setLoading(false)
        setIsModalOpen(true)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
  }

  const handleOk = () => {
    setIsModalOpen(false)

    if (isDirectLink) {
      window.location.href = "https://lpdp.kemenkeu.go.id"
    }
  }

  const beforeUploadHandler = () => {
    return false
  }

  const onChangeHandler = ({ fileList: newFile }) => {
    if (newFile.length > 0) {
      const isPDF = pdfChecker(newFile[0])
      const isFileSizeOK = fileSizeChecker(newFile[0], 7)

      if (!isPDF) {
        message.error("You can only upload PDF file!")
      }

      if (!isFileSizeOK) {
        message.error("The file must smaller than 7MB!")
      }

      if (isPDF && isFileSizeOK) {
        setFileList(newFile)
        form.setFieldsValue({ dokumen_proposal: newFile[0].originFileObj })
      }
    } else {
      setFileList([])
      form.setFieldsValue({ dokumen_proposal: null })
    }
  }

  const itemRenderHandler = (originNode, file, fileList, actions) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <a
          href={file.url || URL.createObjectURL(file.originFileObj)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {file.name}
        </a>
        <span>
          <Button
            type="link"
            href={file.url || URL.createObjectURL(file.originFileObj)}
            target="_blank"
            rel="noopener noreferrer"
            icon={<EyeOutlined />}
          />
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => actions.remove(file)}
          />
        </span>
      </div>
    )
  }

  return (
    <Layout module_name="scholarship" page_name="registration-form">
      <Seo
        title="Form Pendaftaran Business Competition"
        module_name="scholarship"
        page_name="registration-form"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t("Form Pendaftaran Business Competition")}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container>
        <Typography.Title>{t("Form Pendaftaran - Tahap 4")}</Typography.Title>

        <Divider />

        <Form
          {...formConfig}
          form={form}
          name="form-data-usaha"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Nomor Registrasi"
            name="no_registrasi"
            rules={[
              {
                required: true,
                message: "Please input your Registration ID!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="dokumen_proposal"
            label="Upload Proposal Bisnis"
            rules={[
              {
                required: true,
                message: "Please upload Proposal Bisnis!",
              },
            ]}
          >
            <Typography.Text>
              {t(
                "Proposal Bisnis hanya untuk format .pdf dengan maksimum ukuran sebesar 5 Mb."
              )}
            </Typography.Text>
            <Upload
              name="avatar"
              fileList={fileList}
              beforeUpload={beforeUploadHandler}
              onChange={onChangeHandler}
              itemRender={itemRenderHandler}
            >
              {fileList.length > 0 ? null : (
                <Button icon={<UploadOutlined />} style={{ marginTop: 20 }}>
                  Select File
                </Button>
              )}
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>

        <Modal
          visible={isModalOpen}
          footer={[
            <Button
              key="ok"
              type="primary"
              loading={loading}
              onClick={handleOk}
            >
              Ok
            </Button>,
          ]}
          onCancel={() => setIsModalOpen(false)}
        >
          <Typography.Title className="text-center" style={{ marginTop: 15 }}>
            {modalTitle}
          </Typography.Title>

          <p className="text-center">{modalDescription}</p>
        </Modal>
      </Container>
    </Layout>
  )
}

export default TahapEmpatPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
